function preload(arrayOfImages) {
    $(arrayOfImages).each(function(){
        $('<img/>')[0].src = this;
    });
}

// Usage:

preload([
    './assets/images/header1.jpg',
    './assets/images/header2.jpg',
    './assets/images/header3.jpg',
    './assets/images/header4.jpg',
    './assets/images/header5.jpg',
    './assets/images/header6.jpg',
    './assets/images/header7.jpg',
    './assets/images/header8.jpg'
]);

var i = 0;
function f() {
    if (i > 7) {
    	i = 0;
    }
    i++;
	var image = 'url(./assets/images/header'+(i)+'.jpg)'
	$('header').css('background-image', image)
    setTimeout( f, 4000 );
}
f();